import React, { useState } from "react"

import { Link } from "gatsby"

import Layout from "../layout"
import Seo from "../seo"
import Share from "../share"

// import { normalizePath } from "../../utils/get-url-path"
import StaticBG from "../../assets/images/pages/parents-hero.jpg"

function PageChild({ location, data, menu, site }) {
  const { title, content, featuredImage } = data
  const { menuItems } = menu

  const [openSection, setOpenSection] = useState(false)
  const toggleSection = () => {
    setOpenSection(!openSection)
  }

  // const windowInnerWidth =
  //   typeof window !== "undefined" ? window.innerWidth : null
  // const [width, setWidth] = useState(windowInnerWidth)

  // useEffect(() => {
  //   const widthHandle = () => {
  //     const newWidth = windowInnerWidth
  //     setWidth(newWidth)
  //   }

  //   window.addEventListener("resize", widthHandle)

  //   return () => window.removeEventListener("resize", widthHandle)
  // }, [])

  return (
    <Layout toggleSection={toggleSection}>
          <Seo
        title={title}
        description={site.siteMetadata.description}
      />
      {menuItems ? (
        <nav
          id="nav-section"
          // aria-expanded={openSection === true ? "true" : "false"}
          style={openSection === true ? { display: "block" } : null}
        >
          <ul>
            {menuItems.nodes.map((menuItem, i) => {
              return (
                <li key={i} role="none">
                  <Link
                    key={menuItem.id}
                    to={menuItem.path}
                    tabIndex="0"
                    role="menuitem"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {menuItem.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      ) : null}

      <div className="static">
        {!!featuredImage?.node?.localFile?.childImageSharp ? (
          <section
            id="static-hero"
            style={{
              backgroundImage:
                "url(" +
                featuredImage.node.localFile.childImageSharp.resize.src +
                ")",
            }}
          >
            &nbsp;
          </section>
        ) : (
          <section
            id="static-hero"
            style={{ backgroundImage: "url(" + StaticBG + ")" }}
          >
            &nbsp;
          </section>
        )}

        <section id="static-page" className="page-pad">
          <div className="page">
            <div className="column-single">
              <h2>{title}</h2>

              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </section>
        <Share location={location} title={title}/>

      </div>
    </Layout>
  )
}

export default PageChild
