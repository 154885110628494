import React, { useState } from "react";
import { Link } from "gatsby"
import parse from "html-react-parser"
// import { normalizePath } from "../../utils/get-url-path"
import Layout from "../layout"
import Seo from "../seo"
import StaticBG from "../../assets/images/pages/awards-hero.jpg"
import GalleryWidget from "../gallery-widget"

export default function Awards({ data }) {

  const [openSection, setOpenSection] = useState(false);
  const toggleSection = () => {
    setOpenSection(!openSection);
  };

  return (
    <Layout toggleSection={toggleSection}>
      <Seo
        title="The Awards"
        description="The nation’s longest-running, most prestigious recognition program for creative teens"
      />
      		<nav id="nav-section" style={openSection === true ? {display: 'block'} : null }  >
			<ul>
           
          {data.menu.menuItems.nodes.map((menuItem, i) => {
            if (menuItem.parentId) {
              return null
            }

            const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

            return (
              <li key={i}>
                <Link key={menuItem.id} to={path}>
                  {menuItem.label}
                </Link>
              </li>
            )
          })}
       
	
			</ul>
		</nav>
      <div id="awards" className="landing">
        <h2>The Awards</h2>
        <section
          id="landing-hero"
          style={{ backgroundImage: "url(" + StaticBG + ")" }}
        >
          <div id="landing-hero-overlay">
          The nation’s longest-running, most prestigious recognition program for creative teens

          </div>
        </section>
        <section id="landing-callout">
          <div id="landing-callout-main">
           <h3><Link to="/awards/how-to-enter" >How to Enter</Link></h3>
           <Link to="/awards/how-to-enter" ><p>
              Teens in grades 7–12 (ages 13 and up) can apply in 28 categories
              of art and writing.
            </p>
            </Link>
          </div>

          <div id="landing-callout-links">
          {data.callouts.edges.map(callout => (
        
             
              <Link key={callout.node.id} className="link" to={callout.node.uri}>
                
            
           {callout.node.title}
                
             </Link>
              
            ))}


         
          </div>
        </section>
        <section id="landing-features">
          <ul>
          {data.features.edges.map(feature => (
              <li key={feature.node.id}>
                <Link to={feature.node.title === "Scholarships" ? "/scholarships" : feature.node.uri}>
                  <img
                    src={
                      feature.node.featuredImage.node.thumb.childImageSharp.fixed.src
                    }
                    width="400"
                  height="400"
                    alt={feature.node.featuredImage.node.altText}
                  />
                  
                  <span className="title">{feature.node.title}</span><span className="text">{parse(feature.node.excerpt)}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </section>
        {/* <section id="landing-gallery">
          <GalleryWidget />
        </section> */}
      </div>
    </Layout>
  )
}


