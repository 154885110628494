import React, { useState } from "react";
import { Link } from "gatsby"
// import { normalizePath } from "../../utils/get-url-path"
import parse from "html-react-parser"
import Layout from "../layout"
import Seo from "../seo"
import StaticBG from "../../assets/images/pages/programs-hero.jpg"
import GalleryWidget from "../gallery-widget"

export default function Programs({ data }) {
  const [openSection, setOpenSection] = useState(false);
  const toggleSection = () => {

    setOpenSection(!openSection);

   

  };




  return (
    <Layout toggleSection={toggleSection}>
      <Seo
        title="Programs"
        description="The nation’s longest-running, most prestigious recognition program for creative teens"
      />
      		<nav id="nav-section" style={openSection === true ? {display: 'block'} : null }  >
			<ul>
           
          {data.menu.menuItems.nodes.map((menuItem, i) => {
            if (menuItem.parentId) {
              return null
            }

            const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

            return (
              <li key={i}>
                <Link key={menuItem.id} to={path}>
                  {menuItem.label}
                </Link>
              </li>
            )
          })}
       
	
			</ul>
		</nav>
      <div id="programs" className="landing">
        <h2>Programs</h2>
        <section
          id="landing-hero"
          style={{ backgroundImage: "url(" + StaticBG + ")" }}
        >
          <div id="landing-hero-overlay">
          The nation’s longest-running, most prestigious recognition program for creative teens

          </div>
        </section>
        <section id="landing-callout">
          <div id="landing-callout-main">
            <h3><Link to="/programs/exhibitions" >Exhibitions</Link></h3>
            <Link to="/programs/exhibitions" >
              <p>
            Selections created by each year's National Medalists are featured in exhibitions in New York, D.C., and throughout the country.
            </p>
            </Link>
          </div>
          {/* Call Out Links when a menu is created 
          <div id="landing-callout-links">
          {data.callouts.edges.map(callout => (
        
             
              <Link key={callout.node.id} className="link" to={callout.node.uri}>
                
            
           {callout.node.title}
                
             </Link>
              
            ))}


         
          </div> */}
        </section>
        <section id="landing-features">
          <ul>
          {data.features.edges.map(feature => (
              <li key={feature.node.id}>
                <Link to={feature.node.uri}>
                <img
                    src={
                      feature.node.featuredImage.node.thumb.childImageSharp.fixed.src
                    }
                    width="400"
                  height="400"
                    alt={feature.node.featuredImage.node.altText}
                  />
                  
                  <span className="title">{feature.node.title}</span><span className="text">{parse(feature.node.excerpt)}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </section>
        {/* <section id="landing-gallery">
          <GalleryWidget />
        </section> */}
      </div>
    </Layout>
  )
}


