import React, { useState } from "react"
import parse from "html-react-parser"

import Layout from "../layout"
import Seo from "../seo"
import Share from "../share"
import StaticBG from "../../assets/images/pages/parents-hero.jpg"

function Page({ location, data, site }) {




  const { title, content, featuredImage } = data

  const [openSection, setOpenSection] = useState(false)
  const toggleSection = () => {
    setOpenSection(!openSection)
  }


  return (
    <Layout toggleSection={toggleSection}>
    <Seo
        title={title}
        description={site.siteMetadata.description}
      />
      <div className="static">

{!!featuredImage?.node?.localFile?.childImageSharp  ?    <section
   id="static-hero"style={{ backgroundImage: "url(" + featuredImage.node.localFile.childImageSharp.resize.src + ")" }}>&nbsp;</section> : 				<section id="static-no-hero">&nbsp;</section>}

        <section id="static-page" className="page-pad">
          <div className="page">
            <div className="column-single">
              <h2>{title}</h2>



              <div>{parse(content)}</div>

            </div>
          </div>
        </section>
        <Share location={location} title={title}/>
      </div>
    </Layout>
  )
}

export default Page


