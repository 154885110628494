import React from "react"
import { graphql } from "gatsby"


// We're using Gutenberg so we need the block styles
// import "@wordpress/block-library/build-style/style.css"
// import "@wordpress/block-library/build-style/theme.css"


import Page from "../components/template-parts/page-part"
import PageChild from "../components/template-parts/page-part-child"
import Awards from "../components/top-level/awards"
import Programs from "../components/top-level/programs"
import Support from "../components/top-level/support"


// const featuredImage = {
//   fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
//   alt: page.featuredImage?.node?.alt || ``,
// }


const PagePostTemplate = ({ location, data }) => {
  
    let awardsData = {}
    awardsData.page = data.page
    awardsData.site = data.siteMeta
    awardsData.menu = data.awardsMenu
    awardsData.callouts = data.awardCallouts
    awardsData.features = data.awardFeatures
  
    let programsData = {}
    programsData.page = data.page
    programsData.site = data.siteMeta
    programsData.menu = data.programsMenu
    programsData.callouts = data.programsCallouts
    programsData.features = data.programsFeatures
  
    let supportData = {}
    supportData.page = data.page
    supportData.site = data.siteMeta
    supportData.menu = data.supportMenu
    supportData.callouts = data.supportCallouts
    supportData.features = data.supportFeatures
  
    const pagesWithmenus = ["awards", "how-to-enter", "recognition", "programs", "exhibitions", "support"]
  
    if (data.page.title === "Awards") {
      return <Awards data={awardsData} />
    } else if (data.page.title === "Programs") {
      return <Programs data={programsData} />
    } else if (data.page.title === "Support") {
      return <Support data={supportData} />
    } else if (
      !data.page.wpParent || !pagesWithmenus.includes(data.page.wpParent.node.slug) 
    ) {
  
      // Fix this once we get NSPP post types up and running.
    if  (data.page.title === "Meet The Poets") {
      return <PageChild location={location} data={data.page} menu={data.programsMenu} site={data.site} />
    } else {
      return <Page location={location} data={data.page} site={data.site} />
    }
      
      
    } else if (
      data.page.wpParent.node.slug === "awards" ||
      data.page.wpParent.node.slug === "how-to-enter" ||
      data.page.wpParent.node.slug === "recognition"
    ) {
      return <PageChild location={location} data={data.page} menu={data.awardsMenu} site={data.site} />
    } else if (
      data.page.wpParent.node.slug === "programs" ||
      data.page.wpParent.node.slug === "exhibitions" ||
      data.page.wpParent.node.slug === "national-student-poets-program" 
    ) {
 
      return <PageChild location={location} data={data.page} menu={data.programsMenu} site={data.site} />
    } else if (
      data.page.wpParent.node.slug === "support" 
    ) {
  
      return <PageChild location={location} data={data.page} menu={data.supportMenu} site={data.site} />
    } 
  
    
  }

export default PagePostTemplate

// export const pageQuery = graphql`
//   query PagePostById(
//     # these variables are passed in via createPage.pageContext in gatsby-node.js
//     $id: String!
//   ) {
//     # selecting the current page by id
//     page: wpPage(id: { eq: $id }) {
//       id
//       content
//       title
//       date(formatString: "MMMM DD, YYYY")

//       featuredImage {
//         node {
//           altText
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1000, quality: 100) {
//                 ...GatsbyImageSharpFluid_tracedSVG
//               }
//             }
//           }
//         }
//       }
//     }


//   }
// `

export const pageQuery = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      excerpt
      content
      wpParent {
        node {
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
            fixed(height: 400, width: 400, cropFocus: ATTENTION) {
              src
            }
   
            resize(width: 1920, height: 1440, cropFocus: ATTENTION) {
              src
            }
          }
          }
        }
      }
    }
    site {
      siteMetadata {
        author
        description
        title
      }
    }
    awardsMenu: wpMenu(slug: { eq: "awards" }) {
      name
      menuItems {
        nodes {
          label
          url
          path
          connectedNode {
            node {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
    programsMenu: wpMenu(slug: { eq: "programs" }) {
      name
      menuItems {
        nodes {
          label
          url
          path
          connectedNode {
            node {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
    supportMenu: wpMenu(slug: { eq: "support" }) {
      name
      menuItems {
        nodes {
          label
          url
          path
          connectedNode {
            node {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
    awardCallouts: allWpPage(
      filter: { wpParent: { node: { slug: { eq: "how-to-enter" } } } }
    ) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              altText
              caption
              description
              localFile {
                childImageSharp {
                  fluid {
                    src
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    programsCallouts: allWpPage(
      filter: { wpParent: { node: { slug: { eq: "exhibitions" } } } }
    ) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              altText
              caption
              description
              localFile {
                childImageSharp {
                  fluid {
                    src
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    awardFeatures: allWpPage(
      filter: {
        wpParent: { node: { slug: { eq: "awards" } } }
        slug: { ne: "how-to-enter" }
      }, sort: {fields: menuOrder, order: ASC}
    ) {
      edges {
        node {
          id
          title
          excerpt
          uri
          featuredImage {
            node {
              altText
              caption
              description
              thumb: localFile {
                childImageSharp {
                  fixed(height: 400, width: 400, cropFocus: ATTENTION) {
                    aspectRatio
                    src
                  }
                }
              }
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    programsFeatures: allWpPage(
      filter: {
        wpParent: { node: { slug: { eq: "programs" } } }
        slug: { ne: "exhibitions" }
      }, sort: {fields: menuOrder, order: ASC}
    ) {
      edges {
        node {
          id
          title
          excerpt
          uri
          featuredImage {
            node {
              altText
              caption
              description
              thumb: localFile {
                childImageSharp {
                  fixed(height: 400, width: 400, cropFocus: ATTENTION) {
                    aspectRatio
                    src
                  }
                }
              }
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    supportFeatures: allWpPage(filter: {wpParent: {node: {slug: {eq: "support"}}}}, sort: {fields: menuOrder, order: ASC}) {
      edges {
        node {
          id
          title
          excerpt
          uri
          featuredImage {
            node {
              altText
              caption
              description
              thumb: localFile {
                childImageSharp {
                  fixed(height: 400, width: 400, cropFocus: ATTENTION) {
                    aspectRatio
                    src
                  }
                }
              }
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

